import forList from "@/mixin/forList";
var numeral = require('numeral');


export default {
	name: "Orderstatistics",
    noFetchFirst: true,
	mixins: [forList],
	data() {
		return {
			SP_: {
		
                searchRegionId: null,
                searchStartTime: "",
                searchEndTime: "",
			},
			regionList:[]
		};
	},

	methods: {
        /**
         * 金额转换成又逗号分割格式
         * @Author zhuiyi
         * @paramsource数据源
         * @authorzhuiyi 2023/01/04
         */
        NUMERAL(num) {
            if (num == '') {
                return ''
            } else {
                if ((num + '').indexOf(".") != -1) {
                    return numeral(num).format('0,0.00')
                } else {
                    return numeral(num).format('0,0')
                }
            }
        },

        async fetchData() {
            const loading = this.$loading();
			try {
				const res = await this.$axios({
					url: "/api/orderStatistics/queryOrderDealStatistics",
					method: "post",
					data:this.SP_
				});

				if (res.code === 2000) {
	
					const list = res.data.statisticsList;
					this.tableData = list;
					loading.close();	
				} else if (res.code !== 1003)
					throw res
				
			} catch (reason) {
				console.warn("获取数据失败", reason);
				this.$message({
					message: reason.msg || "获取数据失败",
					type: "warning"
				});

				loading.close();	
			}
		},
		async querYear(){
			var today = new Date();
			var year = today.getFullYear();
			this.SP_.searchStartTime=year+"-01-01";
			this.SP_.searchEndTime=year+"-12-31";
		},
		async queryRegion() {
		
			try {
				const res = await this.$axios({
					url: "/api/configuration/queryAllOrg",
					method: "GET"
				});

				if (res.code === 2000) {
	
					const regiondata = res.data.regionList;
					this.regionList = regiondata;

				} else if (res.code !== 1003)
					throw res
				
			} catch (reason) {
				console.warn("获取区域数据失败", reason);
			
			}
		},

        // 点击导出
        async handleExportBtn () {
			let loadingRef;
			try {
				await this.$confirm("确定导出吗", "系统提示")
                loadingRef = this.$loading();
                const res = await this.$axios({
                    url: "/api/orderStatistics/downOrderDealStatistics",
                    method: "post",
					data:this.SP_
                })

				if (res.code === 2000) {
					this.$message({
                        message: "已加入导出任务，去消息管理的下载通知中查看吧",
                        type: "success"
                    })
					loadingRef && loadingRef.close();
	
				} else if (res.code !== 1003) 
					throw res;

			} catch (reason) {
				if (reason !== "cancel") {
					this.$message({
                        message: reason.msg || "导出失败",
                        type: "error"
                    });
				}
				loadingRef && loadingRef.close();
			}
        }
	},

	created () {
		this.queryRegion();
		this.querYear();
        this.fetchData()
		this.auth.export = this.$hasAuthFor("api/orderStatistics/downOrderDealStatistics");
	}


};